import PageTemplate from "template/PageTemplate";
import { Formik } from "formik";
import { Form, Typography } from "antd";
import { Colors, Metrics } from "configs";
import Input from "common/FormInputCustom";
import {MailOutlined, UnlockOutlined, SolutionOutlined} from "@ant-design/icons";
import { Button } from "common";
import { useAuthChangePasswordEmailCpfRequest } from "store/hooks/auth";
import { ChangePasswordEmailCpfValidationSchema } from "./ValidationSchema";
import Filters from "../../utils/Filters";
const { Text } = Typography;

const ChangePasswordEmailCpf = () => {
  const changePasswordEmailCpfRequest = useAuthChangePasswordEmailCpfRequest();

  return (
    <PageTemplate contentStyle={styles.container}>
      <Formik
        initialValues={{
          email:"",
          nuCpf:"",
          password: "",
          confirmPassword: "",
        }}
        onSubmit={changePasswordEmailCpfRequest}
        validationSchema={ChangePasswordEmailCpfValidationSchema}
      >
        {({ values, handleSubmit }) => (
          <Form style={styles.form}>
            <div style={styles.wrapper}>
              <Text
                strong
                style={{ color: Colors.primary, fontSize: Metrics.fontSize.sm }}
              >
                Alterar sua senha
              </Text>
              <div style={styles.inputContainer}>
                <Input
                  placeholder="Digite Seu E-mail de Cadastro"
                  prefix={<MailOutlined />}
                  name="email"
                  value={values.email}
                />
                <Input
                  placeholder="CPF"
                  prefix={<SolutionOutlined />}
                  name="nuCpf"
                  value={Filters.inputMaskCPF(values.nuCpf)}
                  required
                />
                <Input
                  placeholder="Senha"
                  prefix={<UnlockOutlined />}
                  password
                  name="password"
                  value={values.password}
                />
                <Input
                  placeholder="Confirme sua senha"
                  prefix={<UnlockOutlined />}
                  password
                  name="confirmPassword"
                  value={values.confirmPassword}
                />
              </div>
              <Button width="200px" type="submit" onClick={handleSubmit}>
                Alterar
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </PageTemplate>
  );
};

const styles = {
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  wrapper: {
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: Colors.white,
    padding: "40px 60px",
    borderRadius: "20px",
    boxShadow: "0px 1px 6px #0000001A",
    minWidth: "30%",
  },
  form: {
    display: "flex",
    justifyContent: "center",
  },
  inputContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    marginTop: Metrics.spacing.xlg,
    marginBottom: Metrics.spacing.xlg,
    width: "100%",
  },
};

export default ChangePasswordEmailCpf;
