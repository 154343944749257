import React, { useContext, useEffect, useState } from 'react';
import useFetch from '../../../../../hooks/useFetch';
import { Metrics } from '../../../../../configs';
import { CourseWizardContext } from '../index';
import {
  createTopicRequest,
  deleteTopicRequest,
  findTopicsByCourseRequest,
  updateTopicRequest,
} from '../../../../../services/topics';
import { CheckCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { Dropdown, Spin, Table } from 'antd';
import TableMenu from '../../components/TableMenu';
import { AiOutlineMore } from 'react-icons/ai';
import TopicForm from '../../../Topics/Form';
import TrainingForm from '../../../Trainings/Form';

const styles = {
  formContainer: {
    backgroundColor: 'white',
    padding: Metrics.spacing.md,
    borderRadius: Metrics.defaultRadius,
    marginTop: Metrics.spacing.md}
}

const TopicsData = () => {
  const { currentStep, setCurrentStep, course, setCourse } = useContext(CourseWizardContext);
  const [initialValues, setInitialValues] = useState({
    curso_id: course.id,
  });
  const [{ isFetching }, createTopic] = useFetch(
    createTopicRequest,
    '',
    false,
    null,
    () => {},
    () => {
      fetchTopics();
      setInitialValues({curso_id: course.id}) }
    );
  const [{ isFetching: isFetchingUpdate }, updateTopic] = useFetch(
    updateTopicRequest,
    '',
    false,
    null,
    () => {},
    () => {
      fetchTopics();
      setInitialValues({curso_id: course.id})
    });
  const [{ isFetching: isFetchingTopics, data: topics}, fetchTopics] = useFetch(findTopicsByCourseRequest, course.id, true, []);
  const [{ isFetching: isDeletingTopics}, deleteTopic] = useFetch(
    deleteTopicRequest,
    '',
    false,
    false,
    () => {},
    fetchTopics);

  const columns = [
    {
      title: "Título",
      dataIndex: "ds_titulo",
      key: "ds_titulo",
    },
    {
      title: "Descrição",
      dataIndex: "ds_descricao",
      key: "ds_descricao",
    },
    {
      title: "Ativo",
      dataIndex: "active",
      key: "active",
      width: 10,
      render: (text, record) => (
        <span>
          { record.active ?
            <CheckCircleOutlined /> :
            <CloseOutlined />
          }
        </span>
      ),
    },
    {
      title: "Ações",
      dataIndex: "action",
      key: "action",
      width: 10,
      render: (text, record) => (
        <span>
          <Dropdown
            overlay={
              <TableMenu
                record={record}
                handleRemove={deleteTopic}
                handleEdit={(t)=> {
                  setInitialValues({curso_id: course.id, ...t});
                }}
                deleteProp='id'
                descProp='ds_titulo'
                desc='Tópico'
              />}
            placement="bottomRight"
            trigger={["click"]}>
            <AiOutlineMore size={25} style={{ cursor: "pointer" }} />
          </Dropdown>
        </span>
      ),
    },
  ];

  return (
    <div style={styles.formContainer}>
      <h1><b>Tópicos</b></h1>
      <TopicForm
        handleSubmitForm={(val) => {
          if (val.id) {
            updateTopic(val);
          } else {
            createTopic(val);
          }
        }}
         initialValues={initialValues}
         course={course}
         handleCancel={() => {}} />
      <Table style={styles.table} columns={columns} dataSource={topics} />
      {isDeletingTopics &&
      <Spin />
      }
    </div>
  );
};

export default TopicsData;
