import * as Yup from 'yup';
import Validations from "../../utils/Validations";

export const ChangePasswordEmailCpfValidationSchema = Yup.object().shape({
  password: Yup.string().trim().required('O campo Senha é obrigatório.'),
  email: Yup.string()
    .trim()
    .email("O E-mail informado não é valido.")
    .required("O campo E-mail é obrigatório."),
  confirmPassword: Yup.string()
    .trim()
    .required('O campo Confirme sua senha é obrigatório.')
    .oneOf([Yup.ref('password'), null], 'As senhas precisam ser iguais.'),
  nuCpf: Yup.string()
    .trim()
    .nullable()
    .required("O campo CPF é obrigatório.")
    .test("nuCpf", "O CPF informado não é válido", (val) => {
      if (val && val !== "") {
        const { isValid } = Validations.isCPF(val);
        return isValid;
      }
      return true;
    }),
});
