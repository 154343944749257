import api from "./api";
import Filters from "../utils/Filters";

const loginRequest = (credentials) => {
  return api.post(`api/auth/authenticate`, credentials);
};

const facebookRequest = (credentials) => {
  return api.post(`api/auth/facebook`, credentials);
};

const googleRequest = (credentials) => {
  return api.post(`api/auth/google`, credentials);
};

const registerRequest = (credentials) => {
  return api.post(`api/register`, credentials);
};

const accountRequest = () => {
  return api.get(`account`);
};

const accountTenantRequest = () => {
  return api.get(`account-tenant`);
};

const forgotPasswordRequest = (email) => {
  return api.post(`/account/reset-password/init`, email);
};

const forgotPasswordFinishRequest = (password, key) => {
  return api.post(`/account/reset-password/finish`, {
    key,
    newPassword: password,
  });
};

const changePasswordRequest = (data) => {
  return api.post(`api/change-password`, data);
};

const changePasswordEmailCpfRequest = (data) => {
  return api.post(`api/changePasswordByEmailCpf`, data);
};

const tenantConfigRequest = () => {
  return api.get(`configuracao`);
};

const renewTokenNewTenantRequest = (data) => {
  return api.post(`renew-token`, data);
};

const updateCredentialsRequest = (credentials, userId) => {
  return api.post(`api/user/update`, credentials);
};

export {
  loginRequest,
  renewTokenNewTenantRequest,
  accountRequest,
  accountTenantRequest,
  forgotPasswordRequest,
  forgotPasswordFinishRequest,
  changePasswordRequest,
  changePasswordEmailCpfRequest,
  tenantConfigRequest,
  registerRequest,
  facebookRequest,
  googleRequest,
  updateCredentialsRequest,
};
