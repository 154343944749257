import React, { useCallback, useState } from 'react';
import { Colors, Metrics } from 'configs';
import { AlternativeItem, SummaryItem } from './styles';
import { Col, Collapse, Form, Radio, Row } from 'antd';

import { Flex, FormButtons, FormInputDate, FormInputText, FormTextArea, Whitespace } from '../../../components';
import { AiFillCheckCircle } from 'react-icons/ai';
import { CourseValidationSchema } from '../../Backoffice/Trainings/Form/ValidationSchema';
import { TabPaneStyled, TabsStyled } from '../../Backoffice/Trainings/Form/styles';
import { Button, Dropdown } from '../../../common';
import { Formik } from 'formik';
import { CheckCircleOutlined, CheckOutlined, CloseCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { Toast } from '../../../utils';
import useFetch from '../../../hooks/useFetch';
import {
  createExerciseAnswersRequest,
  findQuestionRequest,
} from '../../../services/course';
import { findQuestionValidationRequest } from '../../../services/question';

const { Panel } = Collapse;



const Exercises = ({data, questions, previouslyCompleted, completeFn}) => {
  const [success, setSuccess] = useState(previouslyCompleted);
  const [errors, setErrors] = useState([]);
  const [submitCount, setSubmitCount] = useState(0);
  const radioStyle = {
    marginBottom: Metrics.spacingMD
  };
  const [
    { isFetching: isFetchingQuestions, data: dataQuestions },
  ] = useFetch(findQuestionRequest, data, true, []);
  const [
    { isFetching: isFetchingValidation, data: validation }, validateAnswers
  ] = useFetch(findQuestionValidationRequest, '', false, null);
  const [{isFetching, data: answeredData}, fetch] = useFetch(
    createExerciseAnswersRequest,
    '',
    false,
    [],
  );
  const onSubmit = useCallback(
    (values) => {
      setSubmitCount((s) => ++s);
      const err = [];
      Object.keys(values.questions).map((k) => {
        const correct = dataQuestions
          .find((dq) => dq.id.toString() === k.toString())
          .alternativas.find((a) => {
            return values.questions[k] === a.id;
          }).fg_correta;

        if (!correct) {
          err.push(
            dataQuestions.find((dq) => dq.id.toString() === k.toString())
              .ds_titulo,
          );
        }
      });
      setErrors(err);
/*      if (err.length > 0) {
        Toast.showErrorMessage('Por favor revise suas respostas!');
      } else {*/
        const mappedObject = Object.keys(values.questions).map((y) => ({
          questaoId: parseInt(y),
          alternativaId: values.questions[y],
        }));
        validateAnswers({alternativas: mappedObject});
        // fetch({alternativas: mappedObject});
        setSuccess(true);
      /*}*/
    },
    [dataQuestions, setErrors, setSubmitCount],
  );

  return (
    <Flex justifyContent="flex-start" marginTop="3vw" paddingBottom="35px">
      <div
        style={{
          flex: 1,
          borderRadius: "20px",
        }}
      >
        <Formik
          initialValues={{questions}}
          enableReinitialize={true}
          onSubmit={onSubmit}>
        {({ handleSubmit, values, setFieldValue }) => (
          <Form>
              <Col>
                <Collapse
                  accordion
                  expandIconPosition="right"
                >
                  {dataQuestions && dataQuestions.map((v, i) => (
                    <Panel header={
                      <Row style={{justifyContent: 'space-between', alignItems: 'center'}}>
                        {`${v.nu_ordenacao} - ${v.ds_titulo}`}
                        {/*{
                          values.questions[v.id] &&
                          v.alternativas.find((a) => a.fg_correta).id ===
                          values.questions[v.id] && (
                            <CheckCircleOutlined style={{color: Colors.greenCheck}} />
                          )
                        }
                        {
                          values.questions[v.id] &&
                          v.alternativas.find((a) => a.fg_correta).id !==
                          values.questions[v.id] && (
                            <CloseCircleOutlined style={{color: Colors.error}} />
                          )
                        }*/}
                      </Row>
                    } key={i}>
                        {/*<Flex marginBottom="15px">
                          <SummaryItem color={Colors.primary}>Sua resposta</SummaryItem>
                          <SummaryItem marginLeft color={Colors.greenCheck}>
                            Resposta correta
                          </SummaryItem>
                        </Flex>*/}
                        <span style={styles.exerciseText}>{v.ds_resumo}</span>
                        <Flex marginTop="15px" dir="column">
                          <Radio.Group
                            style={{display: 'flex', flexDirection: 'column'}}
                            onChange={(e) => {
                              if (!previouslyCompleted) {
                                setFieldValue(`questions[${v.id}]`, e.target.value);
                              }
                            }}
                            value={values.questions[v.id]} name={`questions[${v.id}]`}>
                            {v.alternativas.map((a, y) => (
                              <Radio style={radioStyle} value={a.id} key={y}>
                                {a.ds_descricao}
                              </Radio>
                            ))}
                          </Radio.Group>
                        </Flex>
                      </Panel>
                  ))}
                </Collapse>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  {(Object.keys(values.questions).length === dataQuestions.length && errors.length === 0 && submitCount > 0) && (
                    <span style={styles.successText}>Parabéns! Você completou os exercícios com sucesso.</span>
                  )}
                  {previouslyCompleted && (
                    <span style={styles.successText}>Parabéns! Você completou os exercícios com sucesso.</span>
                  )}
                  {
                    validation && !validation.concluido && validation.validacao.map((v) =>
                      <span key={v.questaoId} style={styles.errorText}>{v.dica}</span>
                    )
                  }
                </div>

                  <Row style={{justifyContent: 'flex-end', paddingTop: Metrics.spacingSM}}>
                    {!previouslyCompleted && dataQuestions && dataQuestions.length > 0 && Object.keys(values.questions).length === dataQuestions.length &&
                    <Button onClick={handleSubmit}
                            disabled={Object.keys(values.questions).length === dataQuestions.length && errors.length === 0 && submitCount > 0}>Validar
                      respostas</Button>
                    }
                    <Whitespace width={Metrics.spacing.md} />
                    { validation && validation.concluido &&
                    <Button onClick={() => completeFn(data)}>Concluir treinamento</Button>
                    }
                  </Row>

              </Col>
            </Form>
          )}
        </Formik>
      </div>
    </Flex>
  );
};

const styles = {
  exerciseText: {
    color: Colors.secondary,
    fontSize: "15px",
  },
  successText: {
    color: Colors.greenCheck
  },
  errorText: {
    color: Colors.error
  }
};

export default Exercises;
