import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Dropdown, Menu, Spin, Table, Tabs } from 'antd';
import { Col, Row } from 'components';
import useFetch from '../../../../hooks/useFetch';
import {
  createTopicRequest,
  deleteTopicRequest, findTopicRequest,
  findTopicWithTrainingsRequest,
  updateTopicRequest,
} from '../../../../services/topics';
import { createCourseRequest, updateCourseRequest } from '../../../../services/course';
import TopicForm from '../../Topics/Form';
import TrainingForm from '../../Trainings/Form';
import { createTrainingRequest, deleteTrainingRequest, updateTrainingRequest } from '../../../../services/training';
import { Colors, Metrics } from '../../../../configs';
import {
  ArrowRightOutlined,
  CheckCircleOutlined,
  CloseOutlined,
  DeleteFilled,
  PlayCircleFilled, QuestionCircleTwoTone, QuestionOutlined, SelectOutlined,
} from '@ant-design/icons';
import QuestionForm from '../../Question/Form';
import CourseForm from '../Form';
import { AiOutlineExclamation, AiOutlineMore } from 'react-icons/ai';
import { Modal, Toast } from '../../../../utils';
import TableMenu from '../components/TableMenu';
import CourseData from './CourseData';
import TopicsData from './TopicsData';
import TrainingData from './TrainingData';
import QuestionData from './QuestionData';
import AlternativeData from './AlternativeData';
import {
  IoPlayOutline,
  IoSchool,
} from 'react-icons/io5';

import {
  MdQuestionAnswer,
} from 'react-icons/md';


const {TabPane} = Tabs;

const styles = {
  formContainer: {
    backgroundColor: 'white',
    padding: Metrics.spacing.md,
    borderRadius: Metrics.defaultRadius,
    marginTop: Metrics.spacing.md}
}

export const CourseWizardContext = React.createContext({
  currentStep: 0,
  setCurrentStep: () => {},
  course: null,
  setCourse: () => {}
});

const STEPS = [
  {title: 'Dados do curso', icon: (currentStep) => <IoSchool style={{color: currentStep ? Colors.white : Colors.primary}} />},
  {title: 'Tópicos', icon: (currentStep) => <AiOutlineExclamation style={{color: currentStep ? Colors.white : Colors.primary}} />},
  {title: 'Treinamentos', icon: (currentStep) => <IoPlayOutline style={{color: currentStep ? Colors.white : Colors.primary}} />},
  {title: 'Questões e Alternativas', icon: (currentStep) => <MdQuestionAnswer style={{color: currentStep ? Colors.white : Colors.primary}} />},

]

const InternalCourseWizard = () => {
  const {currentStep, setCurrentStep, course} = useContext(CourseWizardContext);
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <CourseData/>;
      case 1:
        return <TopicsData/>;
      case 2:
        return <TrainingData/>;
      case 3:
        return <QuestionData/>;
      default:
        return 'Unknown step';
    }
  }

  return (
    <div>
      <Row>
        {STEPS.map( (s, i) =>
          <Row style={{justifyContent: 'center', alignItems: 'center'}}>
            <Col onClick={() => {
              if (course.id) {
                setCurrentStep(i);
              } else {
                Toast.showErrorMessage('Necessário salvar o curso para poder prosseguir para os próximos passos.');
              }
            }}
                 style={{
              cursor: 'pointer',
              justifyContent: 'space-around',
              backgroundColor: currentStep === i ? Colors.primary : Colors.white,
              margin: Metrics.spacing.min,
              padding: `${Metrics.spacing.min} ${Metrics.spacing.sm}`,
              width: '100px',
              height: '60px',
              borderRadius: '15px',
              textAlign: 'center',
              boxShadow: '1px 1px 8px #7d7d7d',
              transition: 'all 0.5s ease'
            }}>
              <Row>
                <span style={{fontSize: Metrics.fontSize.lg, fontWeight: 'bolder', color: currentStep === i ? Colors.white : Colors.primary}}>{i + 1}</span>
                <span style={{fontSize: Metrics.fontSize.xxsm, fontWeight: 'bolder', color: currentStep === i ? Colors.white : Colors.primary}}>{s.title}</span>
              </Row>
              <Row style={{justifyContent: 'flex-end'}}>
                {s.icon(currentStep === i)}
              </Row>
            </Col>
            {
              i < STEPS.length - 1 && <ArrowRightOutlined style={{ color: Colors.primary, fontSize: Metrics.fontSize.sm }} />
            }
          </Row>
        )}
      </Row>
      {
        getStepContent(currentStep)
      }
    </div>
  );
}

const CourseWizard = ({ initialValues }) => {
  const [course, setCourse] = useState({
    ds_titulo: '',
    ds_curso: '',
    ds_resumo: '',
    ds_tempo_acesso: '',
    ds_hrs_video: '',
    pr_desconto: '',
    dt_validade_desconto:  null,
    vl_curso: '',
    dt_inicio: null,
    dt_fim:  null,
    ds_imagem: ''
  });
  const [currentStep, setCurrentStep] = useState(0);

  return (
    <CourseWizardContext.Provider
      value={{
          currentStep,
          setCurrentStep,
          course: initialValues || course, setCourse
      }}
    >
      <InternalCourseWizard />
    </CourseWizardContext.Provider>
  );
};

export default CourseWizard;
