import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {findCourseByIdRequest, registerUserCourseRequest, validatePromotionCodeRequest,} from "services/course";
import {Button, Text, Whitespace} from "common";
import {Col, Row} from "components";
import useFetch from "hooks/useFetch";
import pgtCartao from "assets/images/pgmtoCartao.png";
import pgtCartaoBranco from "assets/images/pgmtoCartaoBranco.png";
import pgtCod from "assets/images/pgmtoCodigoBarras.png";
import pgtCodBranco from "assets/images/pgmtoCodigoBarrasBranco.png";
import pgtPix from "assets/images/pgmtPix.png";
import pgtPixBranco from "assets/images/pgmtPixBranco.png";
import {Colors, Metrics} from "configs";
import Input from "common/InputCustom";
import PageTemplate from "template/PageTemplate";
import { Dropdown, Menu, message, Select, Space, Spin } from 'antd';
import * as _ from 'lodash';
import {generateBankSlipAndPickPdfRequest, generateChargeRequest} from "../../../services/payment";
import copyToClipboard from "copy-to-clipboard";
import {Toast} from "../../../utils";
import useDebounce from "../../../hooks/useDebounce";
import {validateUserPendentRequest} from "../../../services/user";
import {AiOutlineCheckCircle, AiOutlineCloseCircle} from "react-icons/ai";
import { Modal as ModalUtils } from "utils";
import Modal from "./components/Modal";
import Filters from "../../../utils/Filters";
import moment from "moment";
import { DownloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {findCardsRequest} from "../../../services/card";
import SelectOrCreateCard from "./components/SelectCard";
import CourseCard from 'features/Courses/CourseBuy/components/CourseCard';
import styled from 'styled-components';
import { findTopicsByCourseRequest } from "../../../services/topics";
import { useAuthUser } from "store/hooks/auth";
import { createCourseModules } from "../../../services/courseModules";

const { Option } = Select;

const PaymentTypesKey = {
  c: 'CREDIT_CARD',
  p: 'PIX',
  b: 'BOLETO'
}

const styles = {
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    width: "100%",
    paddingTop: Metrics.spacing.xlg,
  },
  icon: {
    fontSize: Metrics.fontSize.lg,
    color: Colors.secondary,
  },
  headerNavigation: {
    position: "absolute",
    width: "100%",
    top: 0,
    display: "flex",
    padding: Metrics.spacing.md,
    justifyContent: "space-between",
  },
  dividedContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: '35vw',
    minWidth: 450,
  },
  flexRow: {
    display: "flex",
  },
  paymentOptBtns: {
    display: "flex",
    width: "100%",
    justifyContent: 'center',
    marginTop: Metrics.spacing.md,
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
  },
  headerBtnBlock: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paymentBlock: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 25
  },
  courseInfoBlock: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  paymentOptBtn: {
    position: "relative",
    boxShadow: "1px 1px 7px #cacaca",
    backgroundColor: Colors.white,
    borderRadius: Metrics.defaultRadius,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: `${Metrics.spacing.lg} ${Metrics.spacing.xlg}`,
    cursor: "pointer",
    transition: 'all 0.4s ease',
    flex: 1,
    height: 120
  },
  paymentOptBtnSelected: {
    backgroundColor: Colors.primary,
    color: Colors.white,
  },
  paymentOptImg: {
    width: "40px",
    marginBottom: Metrics.spacing.sm,
  },
  paymentOpts: {
    marginTop: Metrics.spacing.lg,
    width: "100%",
  },
  opacity: {
    opacity: 0.4,
  },
  modalText: {
    fontSize: Metrics.fontSize.sm,
    color: Colors.white,
    fontWeight: 600,
    margin: `${Metrics.spacingLG} 0`,
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  soon: {
    position: "absolute",
    top: "7px",
    right: "-9px",
    transform: "rotate(37deg)",
    padding: "3px 10px",
    color: "#3eb3a9",
    backgroundColor: "white",
    boxShadow: "0px 0px 15px #a9a9a9",
    borderRadius: "6px",
    fontWeight: "bolder",
  },
  codeContainer: {
    backgroundColor: 'white',
    padding: Metrics.spacing.lg,
    borderRadius: Metrics.defaultRadius,
    width: '100%',
    boxShadow: '1px 1px 6px #cacaca',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
};

const CustomSpace = styled(Space)`
  width: 100%;
  .ant-space-item {
    width: 100%;
    max-width: 50%;
  }
`;

/*function bankSlipUtil(pdf, download = false) {
  const view = Base64.converBase64Pdf(pdf);
  const data = new Blob([view], { type: "application/pdf" });
  const url = window.URL.createObjectURL(data);

  if (download) {
    const filename = "Boleto-Studium.pdf";
    const tempLink = document.createElement("a");
    tempLink.href = url;
    tempLink.setAttribute("download", filename);
    tempLink.click();
  } else {
    const win = window.open(url);
    win.onload = function () {
      setTimeout(() => {
        this.document.title = "Impressão de boleto";
      }, 300);
    };
  }
}*/

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CourseBuy = () => {
  const user = useAuthUser();

  let query = useQuery();
  const history = useHistory();
  const { id, topic_id } = useParams();
  const pendentQuery = query.get("pendent");
  const completeData = query.get("complete");
  const [selectCardVisible, setSelectCardVisible] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [installments, setInstallments] = useState(null);
  const [paymentOpt, setPaymentOpt] = useState(null);
  const [promotionalCode, setPromotionalCode] = useState("");
  const [incompleteCredentialsModal, setIncompleteCredentialsModal] =
    useState(false);
  const [{ isFetching: isFetchingCards, data: cards }] = useFetch(
    findCardsRequest,
    null,
    true,
    []
  );
  /*  const [{ isFetching: downloadBankSlipLoading, data: bankSlipDownload }, fetchBankSlipDownload] =
      useFetch(downloadBankSlipById, null, false, null);*/

  // const [
  //   { isFetching: isFetchingRegister, data: registerData },
  //   registerUserCourse,
  // ] = useFetch(registerUserCourseRequest, "", false, null, null, () => history.push(`/my-courses`)
  // );

  const [{ data }] = useFetch(findCourseByIdRequest, id, true, [], null);


  const registerUserCourse = async (_data) => {
    try {
      await registerUserCourseRequest("", _data);

      if(data.has_module){
        const course = await findTopicsByCourseRequest(id)

        const topicPayload = topic_id ??  course.data[0].id

        if(!topicPayload) {
          Toast.showErrorMessage("Erro ao gerar pagamento, tente novamente, se o problema persistir entre em contato conosco!");

          return;
        }

        const payload = {
          users_id: [user.id],
          curso_id: Number(id),
          topico_id: topicPayload,
        };

        await createCourseModules(payload);
      }

      history.push(`/course-progress/${id}`);
    } catch (error) {
      Toast.showErrorMessage("Erro ao liberar o módulo do curso, por favor entre em contato imediatamente conosco!");
      console.log(236)
    }
  }

  const [{ isFetching: chargeLoading, data: chargeData }, generateCharge] =
    useFetch(generateChargeRequest, '', false, null, () => {}, async (res) => {
      try {
        if (typeof res.data === 'string') {
          Toast.showRequestErrorMessage(res.data);
          setSelectCardVisible(false);
        }
        if (res.data.status === 'CONFIRMED') {
          setSelectCardVisible(false);
          registerUserCourse({
            curso_id: id,
            codigoPromo: promotionalCode,
          });

          if(data.has_module){
            const course = await findTopicsByCourseRequest(id)

            const topicPayload = topic_id ?? course.data[0].id

            if(!topicPayload) {
              Toast.showErrorMessage("Erro ao gerar pagamento, tente novamente, se o problema persistir entre em contato conosco!");

              return;
            }

            const payload = {
              users_id: [user.id],
              curso_id: Number(id),
              topico_id: topicPayload
            };

            await createCourseModules(payload);
          }

          Toast.showSuccessMessage('Pagamento confirmado!');
          history.push(`/course-progress/${id}`);
        }
        if (res.data.status === 'PENDING') {
          Toast.showSuccessMessage('Recebemos seu pedido! Estamos processando o pagamento.');
          setSelectCardVisible(false);
          history.push(`/my-courses`);
        }
      } catch (error) {
        Toast.showErrorMessage(`Erro: ${error}`);
      }
    });

  const [
    { isFetching: isFetchingValidateUserPendent, data: userPendentData },
    validateUserPendent,
  ] = useFetch(
    validateUserPendentRequest,
    promotionalCode,
    true,
    null,
    null,
    (e) => {
      if (e.data.pendent) {
        ModalUtils.openModal({
          title: "Atenção",
          icon: ExclamationCircleOutlined,
          description: "Para realizar a compra, você precisa completar o seu cadastro de usuário. Clique em “Atualizar meu perfil” para continuar.",
          okText: "Atualizar meu perfil",
          cancelText: "Fechar",
          okType: "",
          onOk: () => history.push(`/user-credentials?returnTo=/course-buy/${id}/${topic_id ?? null}`),
          onCancel: () => topic_id ? history.push(`/course-progress/${id}`) : history.push(`/course-detail/${id}`),
        });
      }
    }
  );

  const [
    { isFetching: isFetchingDiscount, data: discountData },
    validateDiscount,
  ] = useFetch(
    validatePromotionCodeRequest,
    promotionalCode,
    false,
    null,
    null,
    (dd) => {
      if (dd && dd.data.pendent) {
        localStorage.setItem("codProm", promotionalCode);
        history.push(
          `/user-credentials?course=${id}&pendent=true&code=${promotionalCode}`
        );
      }
      if (dd && dd.data && dd.data.codigoPromocional && dd.data.codigoPromocional.pr_desconto === 100) {
        Toast.showSuccessMessage('Seu código garantiu 100% de desconto para este curso! Clique em acessar.');
      }
    }
  );

  const handleValidateDiscount = useDebounce(
    promotionalCode,
    validateDiscount,
    400
  );

  const menu = useCallback(
    record => {
      return (
        <Menu>
          <Menu.Item
            key="0"
            onClick={() => {
              window.open(record.boleto);
            }}>
            Acessar boleto
          </Menu.Item>
          <Menu.Item
            key="1"
            onClick={() => {
              copyToClipboard(record.linhadigitavel);
              message.success("Linha digitável copiada com sucesso.");
            }}>
            Copiar linha digitável
          </Menu.Item>
        </Menu>
      );
    },
    [history]
  );

  const calculateDiscount = useMemo(() => {
    let value = data.pr_desconto
      ? parseFloat(data.vl_curso) -
      (parseFloat(data.vl_curso) * parseFloat(data.pr_desconto)) / 100
      : data.vl_curso;
    if (discountData && discountData.valid) {
      value =
        value -
        (value * parseFloat(discountData.codigoPromocional.pr_desconto)) / 100;
    }
    return value;
  }, [data, discountData]);

  const getOptStyle = useCallback(
    (opt) => {
      if (opt === paymentOpt) {
        return styles.paymentOptBtnSelected;
      }
    },
    [paymentOpt]
  );

  const handleSetPaymentOpt = useCallback(
    (opt) => {
      validateUserPendent();
      setInstallments(null);
      setPaymentOpt(opt);
    },
    []
  );

  const handleCharge = useCallback(
    async () => {
      if (id !== '3' || (id === '3' && promotionalCode)) {
        if (paymentOpt === 'c') {
          setSelectCardVisible(true);
        } else {
          let topico_id = undefined;

          if(data.has_module){
            const course = await findTopicsByCourseRequest(id);

            const topicPayload = topic_id ?? course.data[0].id

            if(!topicPayload) {
              Toast.showErrorMessage("Erro ao gerar pagamento, tente novamente, se o problema persistir entre em contato conosco!");

              return;
            }

            topico_id = topicPayload;
          }

          generateCharge({
            cursoId: id,
            qtdParcela: installments,
            codPromo: promotionalCode,
            tipoCobranca: PaymentTypesKey[paymentOpt],
            topico_id
          });
        }
      } else {
        Toast.showErrorMessage("Para finalizar a compra, é necessário inserir o código fornecido pela sua paróquia.  Em caso de dúvidas, entre em contato com a coordenação paroquial de ministros.")
      }
    },
    [promotionalCode, installments, id, paymentOpt]
  );

  /*  const handleClickBankSlipOptions = useCallback(
      (download, copy = false) => {
        if (bankSlipData && bankSlipData.arquivo) {
          if (copy) {
            copyToClipboard(bankSlipData.linhaDigitavel);
            message.success("Linha digitável copiada com sucesso.");
          } else {
            bankSlipUtil(bankSlipData.arquivo, download);
          }
        }
      },
      [bankSlipData]
    );*/

  /*  const bsList = useMemo(() => {
      return data
        ? [
          {
            title: "Fazer download do boleto",
            img: downloadIcon,
            onClick: () => handleClickBankSlipOptions(true),
          },
          {
            title: "Copiar linha digitável",
            img: barcode,
            onClick: () => handleClickBankSlipOptions(null, true),
          },
          {
            title: "Imprimir boleto",
            img: imprimirBoleto,
            onClick: () => handleClickBankSlipOptions(false),
          },
        ]
        : [];
    }, [data, bankSlipData]);*/

  const paymentQtProp = useMemo(() => {
    if (paymentOpt === 'b') {
      return 'nu_parcelas_boleto';
    } else if (paymentOpt === 'p') {
      return 'nu_parcelas_pix';
    } else {
      return 'nu_parcelas_cartao';
    }
  }, [paymentOpt]);

  useEffect(() => {
    if (pendentQuery) {
      registerUserCourse({
        curso_id: id,
        codigoPromo: localStorage.getItem("codProm"),
      });
    }
    if (completeData) {
      registerUserCourse({
        curso_id: id,
        codigoPromo: promotionalCode,
      });
    }
  }, [pendentQuery, completeData]);

  return (
    <PageTemplate contentStyle={{display: 'flex', justifyContent: 'center', minHeight: '100vh'}}>
      <div style={styles.dividedContainer}>
        <div style={styles.courseInfoBlock}>
          <Text bold>Você está comprando</Text>
          <Whitespace height={Metrics.spacing.md} />
          <CourseCard
            item={data}
            onClick={() => {}}
            courseValue={calculateDiscount}
            style={{ marginRight: 0 }}
          />
          <div style={styles.paymentBlock}>
            <Text bold>Possui um cupom de desconto ?</Text>
            <Whitespace height={Metrics.spacing.md} />
            <div style={styles.codeContainer}>
              <Input
                placeholder="Informe o seu cupom"
                style={{ background: Colors.softGray, borderRadius: 4, width: '50%', minWidth: 100, height: 40 }}
                onChange={(event) => setPromotionalCode(event.target.value)}
              />
            </div>
          </div>
        </div>
        <div style={{ flex: 1 }}>

        {
          discountData && discountData && discountData.codigoPromocional && discountData.codigoPromocional.pr_desconto === 100 ?
            <div
              style={{
                marginTop: Metrics.spacingXLG,
                display: "flex",
                justifyContent: "center",
              }}>
              <Button
                onClick={() => {
                  registerUserCourse({
                    curso_id: id,
                    codigoPromo: promotionalCode,
                  });
                }}>
                Acessar
              </Button>
            </div> :
              <div style={styles.paymentBlock}>
                <Text bold>Forma de pagamento</Text>
                <div style={styles.paymentOptBtns}>
                  <CustomSpace style={{justifyContent: 'center'}}>
                    {
                      data.fg_cartao &&
                      <div style={{...styles.paymentOptBtn, ...getOptStyle("c")}} onClick={() => handleSetPaymentOpt('c')}>
                        <div style={styles.modalContent}>
                          <img style={styles.paymentOptImg} src={paymentOpt === "c" ? pgtCartaoBranco : pgtCartao} alt=""/>
                          <Text color={paymentOpt === "c" ? Colors.white : Colors.primary} bold>Cartão</Text>
                        </div>
                      </div>
                    }
                    {
                      data.fg_pix &&
                      <div style={{...styles.paymentOptBtn, ...getOptStyle("p")}} onClick={() => handleSetPaymentOpt('p')}>
                        <div style={styles.modalContent}>
                          <img style={styles.paymentOptImg} src={paymentOpt === "p" ? pgtPixBranco : pgtPix} alt=""/>
                          <Text color={paymentOpt === "p" ? Colors.white : Colors.primary} bold>Pix</Text>
                        </div>
                      </div>
                    }
                    {
                      data.fg_boleto &&
                      <div
                        style={{...styles.paymentOptBtn, ...getOptStyle("b")}}
                        onClick={() => handleSetPaymentOpt('b')}
                      >
                        <div style={styles.modalContent}>
                          <img
                            style={styles.paymentOptImg}
                            src={paymentOpt === "b" ? pgtCodBranco : pgtCod}
                            alt=""
                          />
                          <Text
                            bold
                            color={paymentOpt === "b" ? Colors.white : Colors.primary}>
                            Boleto
                          </Text>
                        </div>
                      </div>
                    }
                  </CustomSpace>
                </div>
                {paymentOpt && !chargeData &&
                <Col style={{margin: `${Metrics.spacingMD} 0`, textAlign: 'center'}}>
                  <Text bold>Número de parcelas</Text>
                  <Select placeholder='Selecione o parcelamento' style={{ width: 220 }} value={installments} onChange={setInstallments}>
                    {
                      _.times(data[paymentQtProp], (e) =>
                        (
                          <Option key={e} value={e + 1}>{e === 0 ? 'à vista' : e + 1}</Option>
                        ))
                    }
                  </Select>
                </Col>
                }
                {
                  !chargeLoading ?
                    <Col>
                      {paymentOpt && installments && !chargeData &&
                      <Button
                        disabled={chargeLoading}
                        style={{margin: `${Metrics.spacingMD} 0`}}
                        onClick={handleCharge}>
                        Gerar pagamento
                      </Button>
                      }
                    </Col> :
                    <Spin/>
                }
                {/*            {paymentOpt === "b" && bankSlipData && (
              <div style={styles.paymentOpts}>
                {bsList.map((bso, i) => (
                  <BankSlipOption key={i} bso={bso} />
                ))}
              </div>
            )}*/}
                {chargeData && chargeData.constructor === Array && paymentOpt === 'b' &&
                <div style={{...styles.paymentBlock, marginTop: Metrics.spacingXMD}}>
                  <Text bold>Parcelas</Text>
                  <Col>
                    <Col style={{
                      padding: `${Metrics.spacingMD} 0px`,
                      borderRadius: Metrics.defaultRadius,
                      backgroundColor: Colors.white,
                      width: '100%',
                      boxShadow: '5px 5px 15px #bfb9b9',
                      marginTop: Metrics.spacingMD,
                    }}>
                      {chargeData.map((bs, i) => (
                        <Row key={i} style={{
                          justifyContent: 'space-around',
                          padding: Metrics.spacingSM,
                          backgroundColor: i % 2 !== 0 ? '#f1f1f1' : Colors.white,
                        }}>
                      <span>
                        {bs.nrparcela ? bs.nrparcela : 0}
                      </span>
                          <span>
                        {Filters.convertMoneyTextMask(bs.valordocumento)}
                      </span>
                          <span>
                        {moment(bs.datavencimento, 'YYYY-MM-DD').format(
                          'DD/MM/YYYY'
                        )}
                      </span>
                          <span>
                          <Dropdown
                            disabled={chargeLoading}
                            overlay={menu(bs)}
                            placement="bottomRight"
                            trigger={['click']}>
                            <DownloadOutlined style={{cursor: 'pointer'}} />
                          </Dropdown>
                      </span>
                        </Row>
                      ))}
                    </Col>
                    <Col style={{justifyContent: 'center'}}>
                  <span style={{
                    color: Colors.primary,
                    fontSize: Metrics.fontSize.xsm,
                    marginTop: Metrics.spacingLG,
                    textAlign: 'center'
                  }}>
                    Recebemos seu pedido! Pague os boletos até a data do vencimento. O curso será liberado assim que for confirmado pelo banco o pagamento.
                  </span>
                      <span style={{
                        color: Colors.primary,
                        fontSize: Metrics.fontSize.xsm,
                        textAlign: 'center',
                        fontWeight: 'bolder',
                        marginBottom: Metrics.spacingMD
                      }}>
                    Você também pode acessar seus boletos na página Financeiro, nas suas informações de perfil.
                  </span>
                      <Button
                        onClick={() => history.push(`/my-courses`)}>
                        Voltar para meus cursos
                      </Button>
                    </Col>
                  </Col>
                </div>
                }
              </div>
        }
            </div>

          </div>
      <div
        style={{
          marginTop: Metrics.spacingXLG,
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/*        <Button
          onClick={() => {
            validateUserPendent();
          }}>
          Acessar
        </Button>*/}
      </div>
      <Modal
        text="Inscrição realizada com sucesso!"
        buttonText="Ver o curso"
        onClick={() => history.push(`/course-progress/${id}`)}
        onCancel={() => setPaymentModal(false)}
        visible={paymentModal}
        icon={AiOutlineCheckCircle}
      />
      <Modal
        text="Para continuar precisamos concluir o seu cadastro."
        buttonText="Atualizar cadastro"
        onClick={() => history.push(`/user-credentials?course=${id}`)}
        onCancel={() => setIncompleteCredentialsModal(false)}
        visible={incompleteCredentialsModal}
        backgroundColor={Colors.error}
        icon={AiOutlineCloseCircle}
      />
      <SelectOrCreateCard
        closeFn={() => setSelectCardVisible(false)}
        isFetching={chargeLoading}
        visible={selectCardVisible}
        returnFn={generateCharge}
        has_module={data.has_module}
        cursoId={id}
        installments={installments}
        promotionalCode={promotionalCode}
        topic_id={topic_id}
      />
    </PageTemplate>
  );
};

export default CourseBuy;
