import React, {useCallback, useState} from 'react';
import { Formik } from 'formik';
import {Button, Form, message, Tabs, Upload} from 'antd';
import {Col, FormButtons, FormDropdown, FormInputDate, FormInputText, FormTextArea, Row, Whitespace} from 'components';
import { CourseValidationSchema } from './ValidationSchema';
import { TabPaneStyled, TabsStyled } from './styles';
import useFetch from '../../../../hooks/useFetch';
import { Dropdown, FormCheckbox } from '../../../../common';
import { findTopicsRequest } from '../../../../services/topics';
import { findTrainingsRequest } from '../../../../services/training';
import { findQuestionsRequest } from '../../../../services/question';
import { Metrics } from '../../../../configs';
import {UploadOutlined} from "@ant-design/icons";
import {saveVideoToTraining} from "../../../../services/course";
import {Toast} from "../../../../utils";

const {TabPane} = Tabs;

const TrainingForm = ({ handleSubmitForm, initialValues, handleCancel }) => {
  const [uploading, setUploading] = useState();
  const [fileList, setFileList] = useState([]);
  const [{ isFetching: isFetchingTraining, data: trainings }] = useFetch(findTrainingsRequest, '', true, []);
  const [{ isFetching, data: questions }] = useFetch(findQuestionsRequest, '', true, null);
  const [{ data: trainingSaved }, saveReq] = useFetch(saveVideoToTraining, '', false, null, null,
    () => {
      setUploading(false);
      Toast.showSuccessMessage("Arquivo enviado com sucesso!");
    });
  const props = {
    onRemove: file => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
    },
    beforeUpload: file => {
        setFileList(fl => [...fl, file]);
      return false;
    },
    fileList,
  };

  const handleUpload = useCallback(() => {
    saveReq({trainingId: 2, file: fileList[0]});
  }, [fileList]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmitForm}>
      {({ handleSubmit, values, resetForm }) => (
        <Form>
          {
            initialValues.id &&
              <Col style={{backgroundColor: '#F0F2F5', padding: Metrics.spacingMD, marginBottom: Metrics.spacingMD}}>
                <span><b>Carregar mídia do treinamento</b></span>
                <Col style={{justifyContent: 'center', alignItems: 'center'}}>
                  <Upload {...props}>
                    <Button icon={<UploadOutlined />}>Selecionar arquivo</Button>
                  </Upload>
                  <Button
                    onClick={handleUpload}
                    disabled={fileList.length === 0 || uploading}
                    loading={uploading}
                    style={{ marginTop: 16 }}
                  >
                    {uploading ? 'Carregando...' : 'Carregar'}
                  </Button>
                </Col>
              </Col>
          }
          <FormInputText
            name="ds_titulo"
            label="Título"
            required
            value={values.ds_titulo}
          />
          <FormTextArea
            label="Resumo"
            name="ds_resumo"
            required
            value={values.ds_resumo}
          />
          <FormInputText
            name="ds_url_video"
            label="Url do Vídeo"
            required
            value={values.ds_url_video}
          />
          <FormDropdown
            list={trainings}
            label="Treinamento precedente"
            propertyLabel="ds_titulo"
            propertyValue="id"
            name='precedente_id'
            value={values.precedente_id}
            placeholder="Selecione o treinamento que é pré-requisito deste"
          />
          <Whitespace height={Metrics.spacing.lg} />
          <FormInputText
            type='number'
            label="Porcentagem de acerto do precedente"
            name="pr_acerto_precedente"
            value={values.pr_acerto_precedente}
          />
          <FormInputDate
            label="Data início"
            name="dt_inicio"
            value={values.dt_inicio}
          />
          <FormInputDate
            label="Data fim"
            name="dt_fim"
            value={values.dt_fim}
          />
          <FormCheckbox
            label='Aula presencial'
            name='is_in_person'
            value={values.is_in_person}
          />
          <FormButtons
            bt2={{
              label: 'Salvar',
              onClick: () => {
                handleSubmit(values);
                setTimeout( () => resetForm(), 0)
              }
            }}
          />
        </Form>
      )}
    </Formik>
  );
};

export default TrainingForm;
